import * as types from "./actionTypes";
import { axiosAPI } from "../../../../engine/config/axios";
import { AIR_DELIVERY } from './actionTypes';

export function setDeliveryMethodData(data, alias) {
    return { type: types.SET_DELIVERY_METHODS_DATA, payload: {data, alias} };
}
export function getDeliveryMethodsSuccess(data) {
    return { type: types.GET_DELIVERY_METHODS_SUCCESS, payload: data };
}
export function setDeliveryUpToFloorWightLimit(data) {
  return { type: types.SET_DELIVERY_UPTOFLOORWIGHTLIMIT, payload: data };
}
export function setDeliveryUpToFloor(data) {
    return { type: types.SET_DELIVERY_UPTOFLOOR, payload: data };
}
export function getDeliveryMethodByAliasSuccess(data, alias) {
    return { type: types.GET_DELIVERY_METHOD_BY_ALIAS_SUCCESS, payload: { data, alias } };
}

function setDeliveryMethodActiveSuccess(data) {
    return { type: types.SET_DELIVERY_METHOD_ACTIVE, payload: data };
}

export function setDeliveryChoosedStoreByAliasSuccess(data, alias) {
    return { type: types.SET_DELIVERY_CHOOSED_STORE_BY_ALIAS, payload: { data, alias } };
}

function getDeliveryIntervalsSuccess(data) {
  return { type: types.GET_DMS_INTERVALS_SUCCESS, payload: data.data };
}

function getDeliveryIntervalsError() {
  return { type: types.GET_DMS_INTERVALS_ERROR };
}

function setDeliveryIntervalsDateSuccess(data) {
    return { type: types.SET_DELIVERY_INTERVALS_DATE, payload: data };
}

function setDeliveryIntervalsIntervalSuccess(data) {
    return { type: types.SET_DELIVERY_INTERVALS_INTERVAL, payload: data };
}

function setDeliveryChoosedCourierFormSuccess(data) {
    return { type: types.SET_DELIVERY_CHOOSED_COURIER_FORM, payload: data };
}

function setDeliveryDMSIntervalsFirstLoadByCookiesDataSuccess( data ) {
    return { type: types.SET_DELIVERY_INTERVALS_FIRST_LOAD_BY_COOKIES, payload: data };
}

export function getDeliveryMethods(dataJson) {
    return function(dispatch) {
        return axiosAPI.delivery.getDeliveryMethods(dataJson)
            .then( delivery => {
                dispatch(getDeliveryMethodsSuccess( delivery.data.data ));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function getDeliveryMethodByAlias(dataJson) {
    return function(dispatch) {
        return axiosAPI.delivery.getDeliveryMethodByAlias(dataJson)
            .then( deliveryMethod => {
                dispatch(getDeliveryMethodByAliasSuccess( deliveryMethod.data.data, dataJson.alias ));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function getNPParcelsBySearch(value, cityId) {
    return function () {
        return  axiosAPI.delivery.getNPParcelsBySearch(value, cityId)
            .catch(error => {
                throw error;
            });
    }
}

export function getNPParcelsByOffset(offset = 0, cityId) {
    return function () {
        return axiosAPI.delivery.getNPParcelsByOffset(offset, cityId)
            .catch(error => {
                throw error;
            });
    }
}

export function setDeliveryMethodActive(alias) {
    return function(dispatch, getState) {
        dispatch(setDeliveryMethodActiveSuccess( alias ));
    };
}

export function setDeliveryChoosedStoreByAlias( choosedStoreObj, alias ) {
    return function(dispatch) {
        dispatch(setDeliveryChoosedStoreByAliasSuccess( choosedStoreObj, alias ));
    };
}

export function getDeliveryIntervals(dataJson) {
    return function (dispatch) {
        dispatch(setWaitIntervals(true));
        return axiosAPI.delivery.getDeliveryIntervals(dataJson)
            .then(data => {
                data.data
                    ? dispatch(getDeliveryIntervalsSuccess({ data: data.data }))
                    : dispatch(getDeliveryIntervalsError())
            })
            .catch(error => {
                dispatch(getDeliveryIntervalsError());
                throw error;
            }).finally(() => {
                dispatch(setWaitIntervals(false));
            });
    };
}

export function setDeliveryIntervalsDate( date ) {
    return function(dispatch) {
        dispatch(setDeliveryIntervalsDateSuccess( date ));
    };
}

export function setDeliveryIntervalsInterval( intervalId ) {
    return function(dispatch, getState) {
        dispatch(setDeliveryIntervalsIntervalSuccess( intervalId ));
    };
}

export function setDeliveryChoosedCourierForm( dataJson ) {
    return function(dispatch, getState) {
        dispatch(setDeliveryChoosedCourierFormSuccess( dataJson ));
    };
}

export function setDeliveryDMSIntervalsFirstLoadByCookiesData( bool ) {
    return function(dispatch, getState) {
        dispatch(setDeliveryDMSIntervalsFirstLoadByCookiesDataSuccess( bool ));
    };
}

export const setAirAddressDeliveryChoosedCourierForm = payload => ({
    type: AIR_DELIVERY.SET_DELIVERY_CHOOSED_AIR_ADDRESS_COURIER_FORM,
    payload
});

export const setWaitIntervals = payload => {
    return {
        type: types.SET_WAIT_INTERVALS,
        payload,
    }
};
